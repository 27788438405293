import { template as template_174bd828d88b48418d7180e4668a8fbb } from "@ember/template-compiler";
const EmptyState = template_174bd828d88b48418d7180e4668a8fbb(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
