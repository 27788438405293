import { template as template_4c67f8ebc6d14a94b2444ae84a74e5ff } from "@ember/template-compiler";
const FKText = template_4c67f8ebc6d14a94b2444ae84a74e5ff(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
