import { template as template_f34870e553414d65becb4fbbb10a26f9 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f34870e553414d65becb4fbbb10a26f9(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
