import { template as template_e8b0d02f71b146799610545623a2cdb6 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { getURLWithCDN } from "discourse-common/lib/get-url";
export default class CdnImg extends Component {
    get cdnSrc() {
        return getURLWithCDN(this.args.src);
    }
    get style() {
        if (this.args.width && this.args.height) {
            return htmlSafe(`--aspect-ratio: ${this.args.width / this.args.height};`);
        }
    }
    static{
        template_e8b0d02f71b146799610545623a2cdb6(`
    {{#if @src}}
      <img
        ...attributes
        src={{this.cdnSrc}}
        width={{@width}}
        height={{@height}}
        style={{this.style}}
        alt=""
      />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
