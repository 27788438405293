import { template as template_a6158ba6b3ce4d40be5ca6cf775f906a } from "@ember/template-compiler";
const WelcomeHeader = template_a6158ba6b3ce4d40be5ca6cf775f906a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
